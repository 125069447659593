import React, { useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../../App';

const IframeFlowBuilder = ({ userEmail }) => {
  const iframeRef = useRef(null);
  const { vectorVault } = useContext(AuthContext);
  const hasInitialized = useRef(false);

  // Function to validate required data
  const validateData = () => {
    return !!(vectorVault?.accessToken && userEmail && iframeRef.current);
  };

  // Function to send data to iframe
  const sendDataToIframe = () => {
    if (!validateData()) {
      return false;
    }

    const data = {
      email: userEmail,
      accessToken: vectorVault.accessToken,
      refreshToken: vectorVault.refreshToken,
      tokenExpiresAt: vectorVault.tokenExpiresAt,
    };

    try {
      if (iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(data, window.location.origin);
        hasInitialized.current = true;
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error sending data to iframe:', error);
      hasInitialized.current = false;
      return false;
    }
  };

  // Handle iframe load event
  const handleIframeLoad = () => {
    hasInitialized.current = false;

    let attempts = 0;
    const maxAttempts = 3;
    const retryInterval = 500;

    const attemptSend = () => {
      if (hasInitialized.current) return; // Avoid multiple initializations

      attempts++;
      if (sendDataToIframe()) {
        hasInitialized.current = true;
        return;
      }

      if (attempts < maxAttempts) {
        setTimeout(attemptSend, retryInterval);
      }
    };

    attemptSend(); // Start the first attempt
  };

  useEffect(() => {
    if (!iframeRef.current) return;

    // Add load event listener
    iframeRef.current.addEventListener('load', handleIframeLoad);

    // Cleanup function
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
        hasInitialized.current = false;
      }
    };
  }, [vectorVault, userEmail]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <iframe
        ref={iframeRef}
        src="/FlowBuilder.html"
        title="Vector Flow"
        style={{ flex: 1, border: 'none' }}
      />
    </div>
  );
};

export default IframeFlowBuilder;
