async function deleteService(user, title) {
    const url = "https://deploy.vectorvault.io/delete_service"
    const payload = {
        USER: user,
        TITLE: title
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error('Server responded with an error: ' + errorText);
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error in DELETE request:", error);
        throw error; // Re-throw the error so it can be handled by the caller
    }
}

export default deleteService;