import React, { forwardRef } from 'react';
import DatabaseTable from './DataTable.js';
import './Dashboard.css';


export function DataContainer({ isDarkMode, Ampersandposium, reloadData, showDelete, userEmail, wait, styles }, ref) {
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

          </div>
        </div>
            <div style={{ marginTop: '-50px' }}></div>
        <DatabaseTable Ampersandposium={Ampersandposium} reloadData={reloadData} showDelete={showDelete} userEmail={userEmail} isDarkMode={isDarkMode} wait={wait} styles={styles} />
      </div>
    </>
  );
}

export default forwardRef(DataContainer);