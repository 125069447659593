export const fetchApiKeys = async ( setApiKeys, userEmail ) => {
    try {
        const formData = new URLSearchParams();
        formData.append('email', userEmail);
        const response = await fetch('https://register.vectorvault.io/fetch_keys', {
            method: 'POST',
            headers: {
                'Authorization': 'unexpected_authorization_code'
            },
            body: formData
    });

    if (response.ok) {
        const data = await response.json();
        setApiKeys(data);
    } else {
        console.error('Failed to fetch API keys');
        // Handle non-ok responses here
    }
} catch (error) {
    console.error('Error occurred during fetch:', error);
    // Handle fetch errors here
    }
};