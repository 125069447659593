import encrypt from "./encrypt";
/**
 * Registers a user with the given form data.
 *
 * @param {Object} formData The data from the registration form.
 * @returns {Promise} A promise that resolves if registration is successful and rejects if an error occurs.
 */
export const registerUser = (formData) => {
    let cookieString = document.cookie.split('; ').find(row => row.startsWith('tags'));
    let tags = cookieString ? JSON.parse(decodeURIComponent(cookieString.split('=')[1])) : [];
  
    let completeForm = {
      ...formData,
      password: encrypt(formData.password),
      tags: tags
    };
  
    const url = 'https://register.vectorvault.io/register';
    const headers = new Headers({
      'Authorization': 'expected_authorization_code',
      'Content-Type': 'application/json'
    });
  
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(completeForm),
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then(text => { throw new Error(text); });
      }
    });
  };
  