import React from 'react';

const MarkdownRenderer = ({ content }) => {
  // Function to process the content and split it into segments
  const processContent = (text) => {
    return text.split('\n').map((line, index) => {
      // Handle headers
      if (line.startsWith('### ')) {
        return (
          <h3 key={index} className="text-2xl font-bold mt-6 mb-4">
            {line.replace('### ', '')}
          </h3>
        );
      }
      if (line.startsWith('#### ')) {
        return (
          <h4 key={index} className="text-xl font-bold mt-4 mb-3">
            {line.replace('#### ', '')}
          </h4>
        );
      }
      if (line.startsWith('## ')) {
        return (
          <h2 key={index} className="text-3xl font-bold mt-8 mb-4">
            {line.replace('## ', '')}
          </h2>
        );
      }

      // Handle bullet points
      if (line.startsWith('- ')) {
        return (
          <div key={index} className="ml-4 my-2">
            <span className="mr-2">•</span>
            {processBoldText(line.substring(2))}
          </div>
        );
      }

      // Handle numbered lists
      const numberMatch = line.match(/^\d+\.\s/);
      if (numberMatch) {
        return (
          <div key={index} className="ml-4 my-2">
            <span className="mr-2">{numberMatch[0]}</span>
            {processBoldText(line.substring(numberMatch[0].length))}
          </div>
        );
      }

      // Handle empty lines
      if (line.trim() === '') {
        return <div key={index} className="h-4" />;
      }

      // Handle regular paragraphs
      return (
        <p key={index} className="my-2">
          {processBoldText(line)}
        </p>
      );
    });
  };

  // Function to process bold text
  const processBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return (
          <strong key={index} className="font-bold">
            {part.slice(2, -2)}
          </strong>
        );
      }
      return part;
    });
  };

  return (
    <div className="max-w-3xl mx-auto">
      {processContent(content)}
    </div>
  );
};

export default MarkdownRenderer;