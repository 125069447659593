// Settings.js

import React, { useState, useEffect } from "react";
import { lightStyles, darkStyles } from "../styles";
import ChangePasswordModal from './ChangePasswordModal';
import { billingPortal } from '../api/billingPortal';
import "../dashboard/Dashboard.css";
import { updateSubdomainAPI } from "../api/updateSubdomain";
import { storeAPI, checkAIKey, thirdPartyKeyDelete } from '../api/secureStore.js';
import { APIKeysSection } from "./APIKeysSection.js";
import LoggingToggle from "../dashboard/ToggleLoggle.js";

function Settings({ isDarkMode, userEmail, FirstName, LastName, handleLogout, userPlan, customerID, uuid, setUuid, consoleLogging, setConsoleLogging }) {
    const [firstName, setFirstName] = useState(FirstName);
    const [lastName, setLastName] = useState(LastName);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const styles = isDarkMode ? darkStyles : lightStyles;
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    // Handler for console logging switch
    const handleConsoleLoggingToggle = () => {
        setConsoleLogging(prevState => !prevState);
    };

    // Persist values to localStorage whenever they change
    useEffect(() => {
        if (FirstName && FirstName !== "false") {
            localStorage.setItem('firstName', FirstName);
            setFirstName(FirstName);
        }
        if (LastName && LastName !== "false") {
            localStorage.setItem('lastName', LastName);
            setLastName(LastName);
        }
    }, [FirstName, LastName]);

    // API Key states
    const [apiKeys, setApiKeys] = useState({
        openai: '',
        anthropic: '',
        groq: '',
        grok: '',
    });
    const [displayValues, setDisplayValues] = useState({
        openai: '',
        anthropic: '',
        groq: '',
        grok: '',
    });

    // Safely get the plan display string
    const showPlan = typeof userPlan === 'string' && userPlan.length > 0
        ? userPlan.charAt(0).toUpperCase() + userPlan.slice(1)
        : 'Unknown';
    
    const payingCustomer = customerID !== "null";
    const handleBillingClick = async () => {
        if (!payingCustomer) {
            window.open('https://billing.vectorvault.io/b/7sIeYu1Rr15j0sU8wy', '_blank');
        } else {
            await billingPortal(customerID); // Pass the customerID to the billing portal function
        }
    };

    // ------------------- subdomain stuff ------------------- \\
    const [subdomain, setSubdomain] = useState(uuid);
    const [isEditingSubdomain, setIsEditingSubdomain] = useState(false);
    const [subdomainEditMessage, setSubdomainEditMessage] = useState('');
    const [subdomainEditError, setSubdomainEditError] = useState(false);    

    // Effect to clear the message after a timeout
    useEffect(() => {
        if (subdomainEditMessage) {
            const timer = setTimeout(() => {
                setSubdomainEditMessage('');
                setSubdomainEditError(false);
            }, 5000); // Message will disappear after 5 seconds

            // Cleanup function to clear the timeout if the component unmounts or the message changes
            return () => clearTimeout(timer);
        }
    }, [subdomainEditMessage]);

    const handleEditSubdomain = () => {
        setIsEditingSubdomain(true);
    };

    const handleSaveSubdomain = async () => {
        try {
            setIsLoading(true)
            const result = await updateSubdomainAPI(userEmail, subdomain);
            if (result.success) {
                setUuid(subdomain); // Update the uuid (which shows the subdomain)
                localStorage.setItem('subdomain', subdomain);
                setSubdomainEditMessage(result.message);
                setSubdomainEditError(false);
            } else {
                setSubdomainEditMessage(result.message);
                setSubdomainEditError(true);
            }
        } catch (error) {
            setSubdomainEditMessage(error.message);
            setSubdomainEditError(true);
        } finally {
            setIsLoading(false)
            setIsEditingSubdomain(false);
        }
    };
    // ------------------- end subdomain stuff ------------------- \\

    const toggleChangePasswordModal = () => {
        setShowChangePasswordModal(!showChangePasswordModal);
    };

    // Effect to fetch existing API keys
    useEffect(() => {
        const fetchAPIKeys = async () => {
            try {
                const services = ['openai', 'grok', 'claude', 'groq'];
                const newDisplayValues = {};

                for (const service of services) {
                    const aiKey = await checkAIKey(userEmail, service);
                    if (aiKey) {
                        newDisplayValues[service] = '*'.repeat(45);
                    } else {
                        newDisplayValues[service] = '';
                    }
                }

                setDisplayValues(newDisplayValues);
            } catch (error) {
                console.error('Error fetching AI keys:', error);
            }
        };

        fetchAPIKeys();
    }, [userEmail]);

    // Handlers for API Key Save/Delete
    const handleAPISave = async (service) => {
        await storeAPI(userEmail, service, apiKeys[service]);
        setDisplayValues(prev => ({ ...prev, [service]: '*'.repeat(45) }));
        setApiKeys(prev => ({ ...prev, [service]: '' }));
    };

    const handleAPIDelete = async (service) => {
        setDisplayValues(prev => ({ ...prev, [service]: '' }));
        await thirdPartyKeyDelete(userEmail, service);
    };

    return (
        <div style={{...styles.wrapper }}>
            <div className="App" style={{...styles.body, paddingTop: "50px"}}>
                <h1 style={{ marginBottom: '50px' }}>Settings</h1>
                <h3 style={{ marginTop: "0px", marginBottom: "30px" }}>Plan: <strong>{showPlan}</strong></h3>
                <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    {/* Personal Information */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        First Name:
                        <input
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Last Name:
                        <input
                            type="text"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Email:
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                            readOnly
                        />
                    </label>

                    {/* API Keys */}
                    <APIKeysSection 
                    apiKeys={apiKeys}
                    displayValues={displayValues}
                    setApiKeys={setApiKeys}
                    handleAPISave={handleAPISave}
                    handleAPIDelete={handleAPIDelete}
                    isDarkMode={isDarkMode}
                    styles={styles}
                    />

                    {/* Subdomain Section */}
                    {subdomainEditMessage && (
                        <div style={{ 
                            marginTop: '10px', 
                            color: subdomainEditError ? 'red' : 'green' 
                        }}>
                            {subdomainEditMessage}
                        </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginTop: '10px' }}>
                        {isEditingSubdomain ? (
                            <div style={{ display: 'block' }}>
                            <span style={{ marginRight: '10px' }}>Subdomain:</span>
                                <>
                                    <input
                                        type="text"
                                        value={subdomain}
                                        onChange={(e) => setSubdomain(e.target.value)}
                                        className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                                        style={{ marginRight: '4px', width: '100px' }}
                                    />
                                    <a href='#' style={{ marginRight: '10px', fontSize: '1.1em', textDecoration: 'none' }}>.vectorvault.io</a>
                                    <button type="button" onClick={handleSaveSubdomain} className={isDarkMode ? 'btn-dark' : 'btn-light'}>Save</button>
                                    <button type="button" onClick={() => { setIsEditingSubdomain(false); setSubdomain(uuid); }} className={isDarkMode ? 'btn-dark' : 'btn-light'} style={{ marginLeft: '10px' }}>Cancel</button>
                                    <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                                        <svg viewBox="0 0 50 50">
                                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                                        </svg>
                                    </div>
                                </>
                                <div style={{ marginTop: '10px', fontSize: '0.9em' }}>
                                    The first time you edit your subdomain, it will be free. After that, a $10 change fee will be applied.
                                </div>
                            </div>
                        ) : (
                            <>
                            <span style={{ marginRight: '10px' }}>Subdomain:</span>
                                <a href='#' style={{ marginRight: '10px', fontSize: '1.2em', textDecoration: 'none' }}>
                                    {subdomain}.vectorvault.io
                                </a>
                                <div style={{ marginTop: '4px' }}>
                                    <button type="button" onClick={handleEditSubdomain} className={isDarkMode ? 'btn-dark' : 'btn-light'} >Edit</button>
                                </div>
                            </>
                        )}
                    </div>

                    {/* Console Logging Switch */}
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px', marginBottom: '10px' }}>
                        <label style={{ marginRight: '10px', fontSize: '.97rem' }}>Debug Logging:</label>
                        <div style={{ marginTop: '10px' }}>
                            <LoggingToggle 
                                consoleLogging={consoleLogging}
                                handleConsoleLoggingToggle={handleConsoleLoggingToggle}
                            />
                        </div>
                    </div>

                    {/* Other Links */}
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <a href="#" onClick={toggleChangePasswordModal}>Change Password</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="#" onClick={handleBillingClick}>Billing Portal</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="mailto:support@vectorvault.io">Support</a>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <a href="#" onClick={handleLogout}>Logout</a>
                    </div>
                </form>
                {showChangePasswordModal && (
                    <ChangePasswordModal
                        isOpen={showChangePasswordModal}
                        onClose={toggleChangePasswordModal}
                        userEmail={userEmail}
                        isDarkMode={isDarkMode}
                    />
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}

export default Settings;
