import { tagKey } from "../hooks/useTagManager";
function mod(n, m) { return ((n % m) + m) % m; }
export function encrypt(text) {
  const keyLength = tagKey.length;
  let encryptedText = '';

  for (let i = 0, j = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);

    if (charCode >= 32 && charCode <= 126) {
      const keyCharCode = tagKey.charCodeAt(j % keyLength) - 32;
      encryptedText += String.fromCharCode(mod(charCode + keyCharCode - 32, 95) + 32);
      j++;
    } else {
      encryptedText += text.charAt(i);
    }
  }

  return encryptedText;
}
  
export function decrypt(encryptedText) { 
  const keyLength = tagKey.length;
  let decryptedText = '';

  for (let i = 0, j = 0; i < encryptedText.length; i++) {
    const charCode = encryptedText.charCodeAt(i);

    if (charCode >= 32 && charCode <= 126) {
      const keyCharCode = tagKey.charCodeAt(j % keyLength) - 32;
      decryptedText += String.fromCharCode(mod(charCode - keyCharCode - 32, 95) + 32);
      j++;
    } else {
      decryptedText += encryptedText.charAt(i);
    }
  }

  return decryptedText;
}