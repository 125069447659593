import React from 'react';
import './ToggleSwitch.css';

const LoggingToggle = ({ consoleLogging, handleConsoleLoggingToggle }) => {
  return (
    <div className="toggle-wrapper">
      <div className="toggle-container">
        <label className="toggle-switch">
          <input 
            type="checkbox"
            checked={consoleLogging}
            onChange={handleConsoleLoggingToggle}
          />
          <span className="toggle-slider"></span>
        </label>
      </div>
    </div>
  );
};

export default LoggingToggle;