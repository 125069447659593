import React, { useEffect, useRef } from 'react';
import AceEditor from 'react-ace';

// Import modes
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-swift';
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-ruby";

// Import themes
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-merbivore';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/theme-textmate';

export function AceEditorComponent({ code, type = 'python', isDarkMode, isStreaming = false }) {
  const editorRef = useRef(null);
  const prevCodeRef = useRef(code);

  function updateHeight(editor) {
    if (editor) {
      const lineHeight = editor.renderer.layerConfig.lineHeight;
      const lineCount = code.split('\n').length;
      const padding = 10;
      
      editor.container.style.height = `${lineCount * lineHeight + padding}px`;
      editor.resize();
    }
  }

  useEffect(() => {
    if (editorRef.current && isStreaming) {
      const editor = editorRef.current.editor;
      
      if (prevCodeRef.current !== code) {
        const lastRow = editor.session.getLength() - 1;
        const lastCol = editor.session.getLine(lastRow).length;
        editor.selection.moveTo(lastRow, lastCol);
        editor.scrollToLine(lastRow + 1, true, true, () => {});
        updateHeight(editor);
        prevCodeRef.current = code;
      }
    }
  }, [code, isStreaming]);

  return (
    <div style={{ position: 'relative', width: '100%', marginRight: '-10px' }}>
      <AceEditor
        ref={editorRef}
        mode={type}
        theme={isDarkMode ? 'dracula' : 'clouds'}
        value={code}
        readOnly={true}
        fontSize={14}
        name={`code-editor-${Date.now()}`}
        highlightActiveLine={false}
        editorProps={{ $blockScrolling: true }}
        onLoad={(editor) => {
          updateHeight(editor);
          editorRef.current = { editor };
        }}
        style={{ width: '100%' }}
        showLineNumbers={false}
        showGutter={true}
        setOptions={{
          showPrintMargin: false,
          highlightGutterLine: false,
        }}
      />
    </div>
  );
}

export default AceEditorComponent;