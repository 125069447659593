// conditionalPrint.js

let condition = false; // Internal state

export const setCondition = (newCondition) => {
    condition = newCondition;
};

export const log = (...values) => {
    if (condition) {
        console.log(...values);
    }
};