export async function getFlowNames(email) {
    const url = "https://api.vectorvault.io/load-user-flows";

    const payload = {
      email: email,
    };

    try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
      if (data.status === 'success') {
        // Transform the API response to match the expected format
      const flowNames = data.flows.map(flow => `Flow: ${flow.name}`);
      return flowNames;
    } else {
      console.error('Failed to fetch flows:', data.message);
      return [];
    }
  } catch (error) {
    console.error('Error fetching flows:', error);
    return [];
  }
}