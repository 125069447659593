import { encrypt } from "../utils/crypt";

async function sendDeployRequest( user, title, vault, forwardSlash, chat, data, edit, del, model, dark, context, description, jsn ) {
    const url = "https://deploy.vectorvault.io/deploy"
    const userEncrypted = encrypt(user)
    const payload = {
        env_vars: {
            USER: userEncrypted,
            TITLE: title,
            DESCRIPTION: description,
            FORWARDSLASH: forwardSlash,
            VAULT: vault,
            CHAT: chat,
            DATA: data,
            EDIT: edit,
            DELETE: del,
            MODEL: model,
            DARK: dark,
            CONTEXT: context,
            JSON: jsn,
        }
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            const errorText = await response.text(); // Get response text
            throw new Error('Server responded with an error: ' + errorText);
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error in POST request:", error);
    }
}

export default sendDeployRequest;


// // Example usage
// const url = "http://localhost:8080/deploy";  // Replace with the correct URL if hosted
// makeDeployRequest(
//     url,
//     "your_subdomain",
//     "your_forward_slash",
//     "john.rood@decision.com",
//     "Johns Awesome :)",
//     "hey",
//     "true",
//     "true",
//     "false",
//     "false",
//     "true",
//     "false",
//     "This vector database contains The Prince",
//     "false",
//     "8080"
// ).then(response => {
//     console.log("Response:", response);
// });
