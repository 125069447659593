export async function fetchModelList(email) {
    try {
      const response = await fetch('https://api.vectorvault.io/model_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          secret_token: 'epic barrels for days',
          user: email 
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.models; // Returns the list of models
    } catch (error) {
      console.error('Error fetching model list:', error);
      return [];
    }
}