import React, { useState, useEffect, useCallback, useContext } from "react";
import "../dashboard/Dashboard.css" 
import { fetchModelList } from "../api/modelAPI.js";
import sendDeployRequest from "../api/makeDeployment";
import deleteService from "../api/deleteDeployment";
import Deployments from "./DeploymentsTables";
import { setCookie, getCookie } from '../cookieUtils.js';
import { AuthContext }  from '../App.js'
import { log } from "../utils/logger.js";

function Deploy({ isDarkMode, userEmail, styles, depsBrah, wait, userPlan }) {
    const { vectorVault } = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [description, setDescription] = useState('');
    const [vault, setVault] = useState('');
    const [forwardSlash, setForwardSlash] = useState('');
    const [chat, setChat] = useState(false);
    const [data, setData] = useState(false);
    const [edit, setEdit] = useState(false);
    const [del, setDel] = useState(false);
    const [model, setModel] = useState(false);
    const [dark, setDark] = useState(false);
    const [context, setContext] = useState(true);
    const [JSN, setJSN] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [models, setModels] = useState([]);
    const [showCustomModelInput, setShowCustomModelInput] = useState(false);
    const [customModelName, setCustomModelName] = useState('');
    const [deployments, setDeployments] = useState(depsBrah);
    
    const refreshDeployments = useCallback((newDeployment) => {
        if (newDeployment) {
            // Add the new deployment to the existing ones
            setDeployments(prev => ({
                ...prev,
                [newDeployment.title]: newDeployment
            }));
        }
    }, []);
    
    const [vaults, setVaults] = useState(() => {
        const storedVaults = getCookie('vaults');
        try {
          return storedVaults ? JSON.parse(storedVaults) : ['-'];
        } catch (e) {
          console.error("Error parsing vaults from cookie:", e);
          return ['-'];
        }
    });

    // Load OpenAI models
    useEffect(() => {
        async function loadModels() {
            const fetchedModels = await fetchModelList(userEmail);
            setModels(fetchedModels);
        }
        loadModels();
    }, [userEmail]);

    const handleModelChange = (e) => {
        const selectedModel = e.target.value;
        setModel(selectedModel);
        if (selectedModel === 'finetuned') {
            setShowCustomModelInput(true);
        } else {
            setShowCustomModelInput(false);
            setCustomModelName('');
        }
    };

    const getSelectedModel = () => {
        if (model === 'finetuned') {
            return customModelName.trim();
        } else {
            return model;
        }
    };


    const fetchVaults = useCallback(async () => {
        try {
            const fetchedVaults = await vectorVault.getVaults('');
            
            const updatedVaults = fetchedVaults.length === 0 || fetchedVaults[0] !== "-" 
                ? ["-", ...fetchedVaults] 
                : fetchedVaults;
    
            setVaults(updatedVaults);
    
            const stringifiedVaults = JSON.stringify(updatedVaults);
            setCookie("vaults", stringifiedVaults);
        } catch (error) {
            console.error('Error in fetchVaults:', error);
        }
    }, [userEmail, vault,, setCookie]);

    useEffect(() => {
        try {
            const storedVaults = getCookie("vaults");
            if (storedVaults) {
                const parsedVaults = JSON.parse(storedVaults);
                setVaults(parsedVaults);
            } else {
                fetchVaults();
            }
        } catch (error) {
            console.error("Error parsing stored vaults:");
        }
    }, []);
    
    const validateTitle = (value) => {
        // Regular expression to check for valid characters
        const validTitleRegex = /^[a-zA-Z0-9-\s]+$/;
        
        if (!validTitleRegex.test(value)) {
            setTitleError('Title can only contain letters, numbers, and hyphens.');
            return false;
        }
        setTitleError('');
        return true;
    };

    const handleEdit = (deployment) => {
        log(deployment)
        setTitle(deployment.title || '');
        setDescription(deployment.description || '');
        setVault(deployment.vault || '');
        setForwardSlash(deployment.forwardSlash || '');
        setChat(deployment.chat || false);
        setData(deployment.data || false);
        setEdit(deployment.edit || false);
        setDel(deployment.del || false);
        setModel(deployment.model || false);
        setDark(deployment.dark || false);
        setContext(deployment.context || true);
        setJSN(deployment.JSN || false);
    };
    
    const handleDelete = async (title) => {
        if (window.confirm(`Are you sure you want to delete the deployment "${title}"?`)) {
            try {
                setIsLoading(true);
                const response = await deleteService(userEmail, title);
                log(response);
                setIsLoading(false);
                window.location.reload();
                alert(`Deployment "${title}" deleted successfully.`);
                
                setDeployments(prev => {
                    const newDeployments = { ...prev };
                    delete newDeployments[title];
                    return newDeployments;
                });

            } catch (error) {
                console.error("Error deleting deployment:", error);
                setIsLoading(false);
                alert(`Failed to delete deployment "${title}". Please try again.`);
            }
        }
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        validateTitle(newTitle);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateTitle(title)) {
            return; // Stop submission if title is invalid
        }

        if (userPlan !== 'free') {
            if (chat || data) {
                if (title && vault && forwardSlash) {
                    try {
                        setIsLoading(true)
                        const response = await sendDeployRequest(
                        userEmail, title, vault, forwardSlash, 
                        chat, data, edit, del, 
                        getSelectedModel(), // returns the finetuned model name if it is chosen, or else the model name will be 'finetuned' is you use `model` var
                        dark, context, description, JSN);

                        log(response);

                        // Create new deployment object
                        const newDeployment = {
                            TITLE: title,
                            DOMAIN: response.domain,
                            FORWARDSLASH: forwardSlash,
                            VAULT: vault,
                            DESCRIPTION: description,
                            CHAT: chat,
                            DATA: data,
                            EDIT: edit,
                            DELETE: del,
                            MODEL: getSelectedModel(),
                            DARK: dark,
                            CONTEXT: context,
                            JSON: JSN
                        };

                        // Refresh the deployments list with the new deployment
                        refreshDeployments(newDeployment);

                        setIsLoading(false)
                        alert(`Deployed successfully at url: ${response.domain} You can manage your active deployments below. \n*First deployment takes 15-30 mintues for the domain to go live.`)
                        setTitle('');
                        setDescription('');
                        setVault('');
                        setForwardSlash('');
                        setChat(false);
                        setData(false);
                        setEdit(false);
                        setDel(false);
                        setModel('gpt-4o');
                        setDark(false);
                        setContext(true);
                        setJSN(false);
                        setIsLoading(false);

                        // Refresh the deployments list with the new deployment
                        refreshDeployments(newDeployment);

                    } catch (error) {
                        console.error("Error in POST request:", error);
                        // Handle the error (e.g., show an error message to the user)
                    }
                } else { 
                    setIsLoading(false)
                    alert("'Title', 'Vault', 'Forward Slash', and 'API' are all required varibles that cannot be left blank")
                };
            } else { // no chat or data selected
                setIsLoading(false)
                alert("You must select an interface. Choose from 'Chat', 'Data', or both.")
            };
        } else { // on the free plan
            setIsLoading(false)
            alert('You must be a paying user to make a deployment. Click "SignUp" in the sidebar to conitnue.')
        };
    };

    return (
        <div style={{...styles.wrapper }}>
            <div className="App" style={{...styles.body, paddingTop: "50px"}}>
                <h1 style={{ marginBottom: '10px' }}>Deployments</h1>
                <p style={{ marginBottom: '30px' }}>
                Deployments are $10/mo and they make a live webstie for your vault. It gives you a link so you can share. 
                These Deployments are quick and easy ways to share the AI Agents and Chatbots you build. 
                Choose how visitors interact with your AI, and whether they should just chat with that AI, or be able to see the data too. Full control over read, write and delete. 
                If you have any questions, reach out to our support.
                </p>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>

                    {/* Title input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Title:
                        <input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                        {titleError && <span style={{ color: 'red', fontSize: '0.8em' }}>{titleError}</span>}
                    </label>

                    {/* Description input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Description:
                        <input
                            type="text"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>

                    {/* Vault input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Vault:
                        <select
                            value={vault}
                            onChange={e => setVault(e.target.value)}
                            className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                            style={{ marginTop: '5px' }}
                        >
                            <option value="">Select a vault</option>
                            {vaults.map((v, index) => (
                                <option key={index} value={v}>{v}</option>
                            ))}
                        </select>
                    </label>

                    {/* Forward Slash input */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Forward Slash:
                        <input
                            type="text"
                            value={forwardSlash}
                            onChange={e => setForwardSlash(e.target.value)}
                            className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                            style={{ marginTop: '5px' }}
                        />
                    </label>

                    {/* Model Selection */}
                    <label style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                        Model:
                        <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                            <select
                                className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                                value={model}
                                onChange={handleModelChange}
                                default='gpt-4o'
                                style={{ flex: 1 }}
                            >
                                <option value="">Select a model</option>
                                {models.map((modelItem) => (
                                    <option key={modelItem.model} value={modelItem.model}>
                                        {modelItem.model}
                                    </option>
                                ))}
                                <option value="finetuned">Finetuned Model</option>
                            </select>
                            {showCustomModelInput && (
                                <input
                                    type="text"
                                    placeholder="Enter fine-tuned model name"
                                    value={customModelName}
                                    onChange={(e) => setCustomModelName(e.target.value)}
                                    className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                                    style={{ flex: 1 }}
                                />
                            )}
                        </div>
                    </label>

                    {/* The Bools input */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
                        {[
                            { key: 'chat', displayName: 'Chat', tooltip: 'Allow chat interface' },
                            { key: 'data', displayName: 'Data', tooltip: 'Allow data table interface' },
                            { key: 'edit', displayName: 'Edit', tooltip: 'Allow the adding and editing of data' },
                            { key: 'del', displayName: 'Delete', tooltip: 'Allow the deleting of data' },
                            { key: 'dark', displayName: 'Dark Mode', tooltip: 'Use Dark mode (Defaults to light mode)' },
                            { key: 'context', displayName: 'Context', tooltip: 'By default, all responses are RAG responses using your Vault. Uncheck if you want to turn off RAG responses and NOT use the data in your Vault as context.' },
                            { key: 'JSN', displayName: 'JSON', tooltip: 'Allow users to download/upload the entire database to/from JSON' }
                        ].map(({ key, displayName, tooltip }) => (
                            <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                                <label style={{ display: 'flex', alignItems: 'center' }} title={tooltip}>
                                    {displayName}:
                                    <input
                                        type="checkbox"
                                        checked={eval(key)}
                                        onChange={e => {
                                            log(`Setting ${key}: ${e.target.checked}`); 
                                            eval(`set${key.charAt(0).toUpperCase() + key.slice(1)}(e.target.checked)`)
                                        }}
                                        className={isDarkMode ? 'sleek-checkbox-dark' : 'sleek-checkbox-light'}
                                        style={{ marginLeft: '5px' }}
                                        title={tooltip}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>
                    <button className='button' type="submit" style={{ alignSelf: 'start' }}>Deploy</button>
                    <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                        <svg viewBox="0 0 50 50">
                            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                        </svg>
                    </div>
                </form>
                <Deployments isDarkMode={isDarkMode} styles={styles} depsBrah={deployments} wait={wait} onEdit={handleEdit} onDelete={handleDelete} />
            </div>
        </div>
    );    
}

export default Deploy;
