// App.js
import React, { useState, createContext, useEffect, useCallback, useRef } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { setCookie, getCookie, removeCookie } from './cookieUtils'; // Import the utility functions
import { log, setCondition } from './utils/logger.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import useMediaQuery from './useMediaQuery.js';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar'; 
import LoginForm from './components/LoginForm'; 
import RegistrationForm from './components/RegisterForm';
import APIKeys from './components/APIKeys.js';
import Usage from './components/Usage';
import Settings from './components/Settings.js';
import Dashboard from './dashboard/ChatDashboard.js'; 
import Vaults from './components/VaultTable.js';
import Deploy from './components/Deploy.js';
import IframeFlowBuilder from './components/FlowBuilder/flowBuilderPage.js';
import { registerUser } from './api/registerUser';
import { loginUser } from './api/loginUser';
import { postMail } from './api/credationStation';
import useTagManager from './hooks/useTagManager';
import { darkStyles, lightStyles } from './styles.js';
import Database from './dashboard/DataDashboard.js';
import debounce from 'lodash.debounce';
import VectorVault from 'vectorvault'; // Import VectorVault SDK
import { getFlowNames } from './api/fetchFlows.js';

export const AuthContext = createContext(null);

function App() {
  const navigate = useNavigate();  
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [registrationForm, setRegistrationForm] = useState({ first: '', last: '', email: '', password: '' });
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie('isAuth') === 'true');
  const [userEmail, setUserEmail] = useState(getCookie('userEmail'));
  const breakpoint = 700 * 2;
  const [isMobile, setIsMobile] = useState(useMediaQuery(breakpoint));
  const [depsBrah, setDepsBrah] = useState(false);
  const [error, setError] = useState(null);
  const [Ampersandposium, setAmpersandposium] = useState(getCookie("Ampersandposium"))
  const [lastPath, setLastPath] = useState(() => localStorage.getItem('lastPath') || '/dashboard');
  const [loading, setLoading] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(getCookie('sidebarVisible') !== null ? getCookie('sidebarVisible') === 'true' : true); 
  const [wait, setWait] = useState(true); 
  const [displayValue, setDisplayValue] = useState('');
  const styles = isDarkMode ? darkStyles : lightStyles;
  const vectorVaultRef = useRef(new VectorVault());
  // Modified state initialization section in App.js
  const [userPlan, setUserPlan] = useState(() => {
    return localStorage.getItem('userPlan') || false;
  });
  const [customerID, setCustomerID] = useState(() => {
    return localStorage.getItem('customerID') || false;
  });
  const [FirstName, setFirstName] = useState(() => {
    return localStorage.getItem('firstName') || '';
  });
  const [LastName, setLastName] = useState(() => {
    return localStorage.getItem('lastName') || '';
  });
  const [uuid, setUuid] = useState(() => {
    return localStorage.getItem('uuid') || '';
  });
  const [requests, setRequests] = useState(() => {
    return localStorage.getItem('requests') || false;
  });

  // Initialize console logging from localStorage or default to false
  const [consoleLogging, setConsoleLogging] = useState(() => {
      const savedState = localStorage.getItem('CONSOLE_LOGGING_KEY');
      const initialState = savedState === 'true';
      setCondition(initialState); // Set initial logger condition
      return initialState;
  });

  // Effect to sync console logging state with localStorage
  useEffect(() => {
      localStorage.setItem('CONSOLE_LOGGING_KEY', consoleLogging);
      setCondition(consoleLogging);
  }, [consoleLogging]);

  // Effect to track path changes
  useEffect(() => {
    if (isAuthenticated && !window.location.pathname.includes('/login') && !window.location.pathname.includes('/register')) {
      localStorage.setItem('lastPath', window.location.pathname);
      setLastPath(window.location.pathname);
    }
  }, [isAuthenticated]);

  // Debounced resize handler
  const handleResize = useCallback(debounce(() => {
    setIsMobile(window.innerWidth * window.devicePixelRatio <= breakpoint);
  }, 25), []); // 25ms debounce time

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }; 
  }, [handleResize]);

  useTagManager();

  const handleRegistrationChange = (event) => {
    setRegistrationForm({
      ...registrationForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleLoginChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
    });
  };

  
  const handleLogout = () => {
    removeCookie('userEmail');
    removeCookie('isAuth');
    setIsAuthenticated(false);
    localStorage.setItem('lastPath', window.location.pathname);
    vectorVaultRef.current.logout();

    // Clear localStorage
    localStorage.removeItem('vectorVaultAccessToken');
    localStorage.removeItem('vectorVaultRefreshToken');
    localStorage.removeItem('userPlan');
    localStorage.removeItem('customerID');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('requests');
    localStorage.removeItem('uuid');
  };

  const handleCredGet = async (email = null) => {
    try {
      let postEmail
      if (email) {
        setUserEmail(email);
        postEmail = email
      } else {
        postEmail = userEmail
      }
      setWait(true);
      const result = await postMail(postEmail.toLowerCase());
      setIsAuthenticated(true);
      setCookie('isAuth', true);
      setUserPlan(result.plan);
      setCustomerID(result.id);
      setFirstName(result.fname);
      setLastName(result.lname);
      setRequests(result.requests);
      setUuid(result.uuid);
      setDepsBrah(result.deployments);
    } catch (error) {
      console.error('Error:', error);
      handleLogout();
    } finally {
      setWait(false)
    }
  };
  
  useEffect(() => {
    const authenticate = async () => {
      setLoading(true);
      const isValid = await checkAuthenticationStatus();
      if (!isValid) {
        handleLogout();
      } else {
        await handleCredGet();
      }
      setLoading(false);
    };
    authenticate();
  }, []);

  const handleRegistrationSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    registerUser(registrationForm)
      .then(response => {
        if (response.status === 'success') {
          setIsAuthenticated(true);
          setCookie('isAuth', true);
          setUserEmail(loginForm.email);
          setCookie('userEmail', loginForm.email);
            handleCredGet();
        } else {
          setError(response.message);
        }
      })
      .catch(error => setError(error.message))
      .finally(() => setLoading(false));
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const result = await loginUser(loginForm);
      if (result.success) {
        await vectorVaultRef.current.login(loginForm.email, loginForm.password);
        
        // Store tokens after successful login
        const accessToken = vectorVaultRef.current.getAccessToken();
        const refreshToken = vectorVaultRef.current.getRefreshToken();
        localStorage.setItem('vectorVaultAccessToken', accessToken);
        localStorage.setItem('vectorVaultRefreshToken', refreshToken);
        // Navigate to last path after successful login
        navigate(lastPath);
  
        setIsAuthenticated(true);
        setCookie('isAuth', true);
        setUserEmail(loginForm.email);
        setCookie('userEmail', loginForm.email);
        handleCredGet(loginForm.email);
      } else {
        setError(result.message || 'Login failed');
      }
    } catch (error) {
      setError(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const checkAuthenticationStatus = async () => {
    setWait(true)
    const storedAccessToken = localStorage.getItem('vectorVaultAccessToken');
    const storedRefreshToken = localStorage.getItem('vectorVaultRefreshToken');
    
    if (!storedAccessToken || !storedRefreshToken) {
      log('tokens stored', false)
      return false;
    }

    try {
      // Set the tokens
      vectorVaultRef.current.setAccessToken(storedAccessToken);
      vectorVaultRef.current.setRefreshToken(storedRefreshToken);
      
      // Attempt to make a call
      const vlts = await vectorVaultRef.current.getVaults('');
      const refreshSuccess = Array.isArray(vlts);
      log('refreshSuccess', refreshSuccess);
      if (refreshSuccess) {
        const flowNames = await getFlowNames(userEmail);
        const combinedVaults = [...vlts, ...flowNames, 'Create New'];
        setCookie('vaults', JSON.stringify(combinedVaults))
      }
      
      if (!refreshSuccess) {
        // Attempt to refresh the token
        const refreshSuccess2 = vectorVaultRef.current.refreshAccessToken();
        log('refreshSuccess2', refreshSuccess2)
        if (refreshSuccess2) {
          const accessToken = vectorVaultRef.current.getAccessToken();
          const refreshToken = vectorVaultRef.current.getRefreshToken();
          localStorage.setItem('vectorVaultAccessToken', accessToken);
          localStorage.setItem('vectorVaultRefreshToken', refreshToken);
          const vlts2 = await vectorVaultRef.current.getVaults('');
          const flowNames = await getFlowNames(userEmail);
          const combinedVaults = [...vlts2, ...flowNames, 'Create New'];
          setCookie('vaults', JSON.stringify(combinedVaults))
        }
        return refreshSuccess2
      }

      return true;

    } catch (error) {
      return false;
    } finally {
      setWait(false)
    }
  };

  // Effect to reapply console logging state when component mounts or when navigating back
  useEffect(() => {
    const handleStorageChange = (e) => {
        if (e.key === 'CONSOLE_LOGGING_KEY') {
            const newValue = e.newValue === 'true';
            setConsoleLogging(newValue);
            setCondition(newValue);
        }
    };

    // Listen for changes in other tabs/windows
    window.addEventListener('storage', handleStorageChange);

    return () => {
        window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className="App" style={{ display: 'flex', flexDirection: isAuthenticated ? 'row' : 'column', height: '100vh' }}>
        {isAuthenticated && <Sidebar setIsDarkMode={setIsDarkMode} isDarkMode={isDarkMode} styles={styles} userEmail={userEmail} sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} customerID={customerID} setIsAuthenticated={setIsAuthenticated} isMobile={isMobile} />} 
          <div style={{ flex: 1, overflowY: 'auto' /* Allows Sidebar */ }}>
            <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, vectorVault: vectorVaultRef.current }}>
                <div className="Content"> 
                  <Routes>
                    <Route path="/login" element={!isAuthenticated ? <LoginForm form={loginForm} handleChange={handleLoginChange} handleSubmit={handleLoginSubmit} loading={loading} error={error} /> : <Navigate to="/dashboard" />} />
                    <Route path="/register" element={!isAuthenticated ? <RegistrationForm form={registrationForm} handleChange={handleRegistrationChange} handleSubmit={handleRegistrationSubmit} loading={loading} error={error} /> : <Navigate to="/dashboard" />} />
                    <Route path="/deployments" element={isAuthenticated ? <Deploy isDarkMode={isDarkMode} userEmail={userEmail} styles={styles} uuid={uuid} depsBrah={depsBrah} wait={wait} userPlan={userPlan} /> : <Navigate to="/login" />} />
                    <Route path="/database" element={isAuthenticated ? <Database Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} isDarkMode={isDarkMode} styles={styles} wait={wait} isMobile={isMobile} /> : <Navigate to="/login" />} />
                    <Route path="/dashboard" element={isAuthenticated ? <Dashboard Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} isDarkMode={isDarkMode} styles={styles} wait={wait} isMobile={isMobile} displayValue={displayValue} setDisplayValue={setDisplayValue} /> : <Navigate to="/register" />} />
                    <Route path="/vector-flow" element={isAuthenticated ? <IframeFlowBuilder userEmail={userEmail} /> : <Navigate to="/login" />} />
                    <Route path="/vaults" element={isAuthenticated ? <Vaults userEmail={userEmail} isDarkMode={isDarkMode} wait={wait} styles={styles} /> : <Navigate to="/login" />} />
                    <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/register"} />} />
                    <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/register"} />} />
                    <Route path="/apikeys" element={isAuthenticated ? <APIKeys isDarkMode={isDarkMode} sidebarVisible={sidebarVisible} userEmail={userEmail} setLoading={setLoading} loading={loading} /> : <Navigate to="/login" />} />
                    <Route path="/usage" element={isAuthenticated ? <Usage isDarkMode={isDarkMode} usage={requests} plan={userPlan} handleCredGet={handleCredGet} userEmail={userEmail} wait={wait} /> : <Navigate to="/login" />} />
                    <Route path="/settings" element={isAuthenticated ? <Settings isDarkMode={isDarkMode} userEmail={userEmail} FirstName={FirstName} LastName={LastName} handleLogout={handleLogout} userPlan={userPlan} uuid={uuid} setUuid={setUuid} consoleLogging={consoleLogging} setConsoleLogging={setConsoleLogging} /> : <Navigate to="/login" />} />
                  </Routes> 
                </div>
            </AuthContext.Provider>
          </div>
      {!isAuthenticated && <Footer style={{ flex: 1 }} />}
    </div> 
  );
}

export default App;