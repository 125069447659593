// APIKeysSection.js
export const APIKeysSection = ({ apiKeys, displayValues, setApiKeys, handleAPISave, handleAPIDelete, isDarkMode, styles }) => {
    return (
      <div style={{ marginBottom: '30px' }}>
        <br /><br /><h3>AI Keys</h3>
        {['openai', 'claude', 'groq', 'grok'].map(service => (
          <div key={service} style={{ 
            marginBottom: '20px',
            maxWidth: '500px'  // Limit overall width to ensure consistent layout
          }}>
            <label style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              marginBottom: '5px' 
            }}>
              {service.charAt(0).toUpperCase() + service.slice(1)} API Key:
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '10px',
                marginTop: '5px'
              }}>
                <input
                  type="text"
                  value={apiKeys[service] || displayValues[service]}
                  onChange={e => setApiKeys(prev => ({ ...prev, [service]: e.target.value }))}
                  className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'}
                  style={{ 
                    flex: '1 1 250px',
                    minWidth: '200px',
                    maxWidth: '500px'
                  }}
                />
                <div style={{ 
                  display: 'flex', 
                  gap: '10px',
                  flex: '0 0 auto'
                }}>
                  <button
                    type="button"
                    style={{ ...styles.btn }}
                    onClick={() => handleAPISave(service)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    style={{ ...styles.btn }} 
                    onClick={() => handleAPIDelete(service)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </label>
          </div>
        ))}
      </div>
    );
};