import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../useMediaQuery';
import logo from '../Vector Vault Icon.png';
import { lightStyles } from '../styles';

const RegistrationForm = ({ form, handleChange, handleSubmit: originalHandleSubmit, loading, error }) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 768 * 2 });
  const navigate = useNavigate();

  // Wrap the original handleSubmit to include redirection after form submission
  const handleSubmitAndRedirect = (event) => {
    event.preventDefault();
    // Add check for password match before calling the original handleSubmit
    if (form.password !== confirmPassword) {
      alert("Passwords do not match.");
      return; // Prevent form submission if passwords don't match
    }
    originalHandleSubmit(event);

    // Prepare query parameters for Stripe
    const queryParams = new URLSearchParams({
      'prefilled_email': form.email,
      'client_reference_id': `${form.first}_${form.last}`,
      'prefilled_first_name': form.first,
      'prefilled_last_name': form.last
    }).toString();

    // Redirect to the billing page after successful registration
    window.location.href = `https://billing.vectorvault.io/b/dR68A68fP29n4Ja5kq?${queryParams}`;
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  // Update to handle changes for both form fields and confirmation password
  const handleInputChange = (event) => {
    if (event.target.name === 'confirmPassword') {
      setConfirmPassword(event.target.value);
    } else {
      handleChange(event);
    }
  };

  // Style for the confirm password input when passwords match
  const confirmPasswordStyle = form.password && confirmPassword === form.password ? {
    backgroundImage: "url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22><path fill=%22%2300A300%22 d=%22M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z%22/></svg>')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    paddingRight: '30px' // Adjust padding to prevent text overlap
  } : {};

  return (
    <div style={{ ...lightStyles.body }}>
      <h1 style={{ ...lightStyles.h1, textAlign: 'left', fontSize: isMobile ? "2.8rem" : "3.5rem", display: "flex", alignItems: "left", marginTop: isMobile ? "0px" : "8px"}}>
        <img src={logo} className="App-logo" alt="logo" style={{height: isMobile ? "4rem" : "4.5rem", marginRight: "1rem", marginTop: isMobile ? "-6px" : "-3px"}} />
        Vector Vault
      </h1>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="form-container mt-5 p-4 rounded bg-white shadow">
            <h2 className="text-center mb-4">Register</h2>
            <form onSubmit={handleSubmitAndRedirect}>
              <div className="form-group">
                <label>First name:</label>
                <input type="text" className="form-control" name="first" value={form.first} onChange={handleChange} />
              </div>
              <br />
              <div className="form-group">
                <label>Last name:</label>
                <input type="text" className="form-control" name="last" value={form.last} onChange={handleChange} />
              </div>
              <br />
              <div className="form-group">
                <label>Email:</label>
                <input type="email" className="form-control" name="email" value={form.email} onChange={handleChange} />
              </div>
              <br />
              <div className="form-group">
                <label>Password:</label>
                <input type="password" className="form-control" name="password" style={confirmPasswordStyle} value={form.password} onChange={handleInputChange} />
              </div>
              <br />
              <div className="form-group">
                <label>Confirm Password:</label>
                <input type="password" className="form-control" name="confirmPassword" style={confirmPasswordStyle} value={confirmPassword} onChange={handleInputChange} />
              </div>
              {form.password && confirmPassword && form.password !== confirmPassword && (
                <div style={{ color: 'red' }}>Passwords do not match.</div>
              )}
              <br />
              <button type="submit" style={{ ...lightStyles.button }} disabled={loading}>Register</button>
              <button type="button" className="btn btn-link btn-block" onClick={handleLoginClick}>  Login</button>
            </form>
            {loading && <div className="alert alert-info" style={{ marginTop: '20px' }}>Loading...</div>}
            {error && <div className="alert alert-danger" style={{ marginTop: '20px' }}>An error occurred: {error}</div>}
          </div>
          <br /><br /><br /><br /><br />
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
