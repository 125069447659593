import CryptoJS from 'crypto-js';

const encrypt = (text) => {
    const salt = CryptoJS.lib.WordArray.random(16);   
    const iv = CryptoJS.lib.WordArray.random(16);   
    const key = CryptoJS.PBKDF2('epic barrels for days', salt, { keySize: 256 / 32, iterations: 1000, hasher: CryptoJS.algo.SHA1 });
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
};

export default encrypt;