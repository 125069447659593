import React, { useState, useEffect, useContext } from 'react';
import '../dashboard/Dashboard.css'; 
import styled from 'styled-components';
import { AuthContext }  from '../App.js'

// Styled Components
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;

  th, td {
    padding: 10px;
    font-size: 1.1rem;
    border: 1px solid ${props => props.isDarkMode ? '#333' : '#fff'};
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: ${props => props.isDarkMode ? '#fff' : '#333'};
  }

  tr:nth-child(even) {
    background-color: ${props => props.isDarkMode ? '#2e333c' : '#f7f7f7'};
  }

  tr:hover {
    background-color: ${props => props.isDarkMode ? '#414d68' : '#eef6fc'};
  }

  th:nth-child(1), td:nth-child(1) {
    width: 20%; // Adjusted width for the 'Vault' column
  }

  th:nth-child(2), td:nth-child(2) {
    width: 15%; // Adjusted width for the 'Total Items' column
  }

  th:nth-child(3), td:nth-child(3) {
    width: 20%; // Adjusted width for the 'Last Update' column
  }

  th:nth-child(4), td:nth-child(4) {
    width: 20%; // Adjusted width for the 'Total Use' column
  }

  th:nth-child(5), td:nth-child(5) {
    width: 20%; // Adjusted width for the 'Last Use' column
  }

  @media (max-width: 768px) {
    th, td {
      padding: 5px;
    }

    th:nth-child(1), td:nth-child(1) {
      width: 25%;
    }
  
    th:nth-child(2), td:nth-child(2) {
      display: none; // Optionally hide this column on smaller screens
    }

    th:nth-child(3), td:nth-child(3) {
      width: 25%;
    }

    th:nth-child(4), td:nth-child(4) {
      width: 25%;
    }

    th:nth-child(5), td:nth-child(5) {
      width: 25%;
    }
  }
`;


const StyledTableContainer = styled.div`
    padding: 20px;
    overflow-x: auto; // Enable horizontal scrolling

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PaginationButton = styled.button`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
    margin-right: 5px; // Less margin on mobile
  }
`;

const PageIndicator = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
  }
`;

const JumpToInput = styled.input`
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
  }
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
    margin-top: 10px; // Add space between sections on mobile
  }
`;

const transformData = (incomingData) => {
  if (!incomingData || !Array.isArray(incomingData)) return [];
    return incomingData.map(vault => ({
      vault: vault.vault, // Name of the vault
      totalItems: vault.total_items || '-', // Total items in the vault
      lastUpdate: formatTimestamp(vault.last_update) || '-', // Last update timestamp
      totalUse: vault.total_use || '-', // Total use count
      lastUse: formatUnixTimestamp(vault.last_use) === 'Invalid Date' ? '-' : formatUnixTimestamp(vault.last_use), // Last use timestamp
      }
    )
  );
};

function formatTimestamp(timestamp) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

function formatUnixTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

// Main Component
function Vaults({ userEmail, isDarkMode, styles }) {
  const { vectorVault } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [databaseData, setDatabaseData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]); // Added displayedData state
  const [totalItems, setTotalItems] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [desiredPage, setDesiredPage] = useState(1);

  const fetchData = async () => {
      try {
          const accountData = await vectorVault.getAccountData();
          const transformedData = transformData(accountData);
          setDatabaseData(transformedData);
          setTotalItems(transformedData.length);
          setIsDataLoading(false);
      } catch (error) {
          console.error("Error fetching account data:", error);
      }
  };

  const handlePage = (newPage) => {
      setCurrentPage(newPage);
  };

  // useEffect for initial data fetch
  useEffect(() => {
      fetchData(); // Fetch data on initial render
  }, []); 

    // useEffect for pagination
    useEffect(() => {
      if (databaseData.length > 0) {
          const start = (currentPage - 1) * itemsPerPage;
          const end = start + itemsPerPage;
          setDisplayedData(databaseData.slice(start, end));
      }
  }, [currentPage, itemsPerPage, databaseData]); // Depend on currentPage, itemsPerPage, and databaseData


  return (
      <div style={styles.wrapper}>
        <div className="App" style={{...styles.body, paddingTop: "10px"}}>
        <StyledTableContainer>
            {isDataLoading ? (
                // Render the loading spinner when isDataLoading is true
                <div className="loading-spinner" style={{ display: 'block' }}>
                    <svg viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                    </svg>
                </div>
            ) : (
                <>
                <h2 style={{marginTop: "70px"}}>
                    Vector Databases: <span> {databaseData.length} </span>
                </h2>
                <StyledTable isDarkMode={isDarkMode}>
                    <thead>
                        <tr>
                            <th>Vault</th>
                            <th>Total Items</th>
                            <th>Last Update</th>
                            <th>Total Use</th>
                            <th>Last Use</th>
                        </tr>
                    </thead>
                    <tbody>
                        {databaseData.map((vault, index) => (
                            vault ? (
                                <tr key={index}>
                                    <td>{vault.vault}</td>
                                    <td>{vault.totalItems}</td>
                                    <td>{vault.lastUpdate}</td>
                                    <td>{vault.totalUse}</td>
                                    <td>{vault.lastUse}</td>
                                </tr>
                            ) : null
                        ))}
                    </tbody>
                </StyledTable>
            
            {/* Pagination controls */}
            <PaginationContainer>
            <div>
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginLeft: "0px" }}
                onClick={() => handlePage(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                >
                Previous
                </PaginationButton>
                <PageIndicator>
                Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
                </PageIndicator>
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginLeft: "0px" }}
                onClick={() => handlePage(Math.min(currentPage + 1, Math.ceil(totalItems / itemsPerPage)))}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                >
                Next
                </PaginationButton>
            </div>
            <PaginationSection>
                <a style={{ marginTop: '0px' }}>Page: </a>
                <JumpToInput 
                className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
                style={{ marginTop:"-0px", maxWidth: "75px" }} 
                type="number" 
                value={desiredPage}
                onChange={e => setDesiredPage(Number(e.target.value))}
                />
                <PaginationButton 
                className={ isDarkMode ? 'btn-dark' : 'btn-light' }
                style={{ marginTop:"2px", marginRight: "0px" }} 
                onClick={() => setCurrentPage(Math.max(1, Math.min(desiredPage, Math.ceil(totalItems / itemsPerPage))))}
                >
                Go
                </PaginationButton>
            </PaginationSection>
            </PaginationContainer>
    
            {/* Bottom of Page Spacing */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            </>
        )}
        </StyledTableContainer>
      </div>
    </div>
  );
}


export default Vaults;
