import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import '../dashboard/Dashboard.css'; 
import { lightStyles, darkStyles } from '../styles'; // Importing styles
import { fetchApiKeys } from '../api/fetchKeys';
import { deleteKey } from '../api/deleteKey';
import { newAPI } from '../api/getNewAPI';
import { Check } from 'lucide-react';
import { log } from '../utils/logger';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; // Ensures that your column widths are respected

  th, td {
    padding: 10px;
    border: 1px solid ${props => props.isDarkMode ? '#333' : '#e0e0e0'};
    text-align: center;
    vertical-align: middle;
    overflow: hidden; // Hide overflow content
    text-overflow: ellipsis; // Show ellipsis for overflow text
    white-space: nowrap; // Keep the content in a single line
    color: ${props => props.isDarkMode ? '#ddd' : '#333'}; // Light text for dark mode
  }

  tr:nth-child(even) {
    background-color: ${props => props.isDarkMode ? '#3d424e' : '#f7f7f7'};
  }

  tr:hover {
    background-color: ${props => props.isDarkMode ? '#58688b' : '#eef6fc'};
  }

    th:nth-child(1), td:nth-child(1) {
        width: 25%; // Adjusted width for 'Name' column
    }

    th:nth-child(2), td:nth-child(2) {
        width: 45%; // Adjusted width for 'API' column
    }

    th:nth-child(3), td:nth-child(3) {
        width: 20%; // Adjusted width for 'Created' column
    }

    th:nth-child(4), td:nth-child(4) {
        width: 10%; // Adjusted width for 'Delete' column
    }

  // Media query for mobile devices
  @media (max-width: 768px) {
    th, td {
      padding: 5px; // Reduced padding
      font-size: 14px; // Reduced font size
    }
  }
`;

const StyledTableContainer = styled.div`
    padding: 20px;

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

// Styled Components for Modal
const Modal = styled.div`
  display: block; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4);
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  margin-top: -23px;
  margin-right: -10px;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
`;

const ModalFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; // Add some space below the container
`;

const FullWidthInput = styled.input`
  flex-grow: 1; // Make input take all available space
  margin-right: 10px; // Add some space between input and button
`;

const ModalContent = styled.div`
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 8px;
    width: 80%;
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Center the modal
    `;

const NotificationPopup = styled.div`
    position: absolute;
    right: 0;
    top: -45px;
    background-color: #10b981;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s ease-in-out;
    
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  `;
  

const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return date.toLocaleDateString("en-US", { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
};

// APIKeysPage Component
const APIKeysPage = ({ isDarkMode, sidebarVisible, userEmail, setLoading, loading, pass }) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [newApiKey, setNewApiKey] = useState('');
    const [showModal, setShowModal] = useState(false);
    const styles = isDarkMode ? darkStyles : lightStyles
    const [modalStyle, setModalStyle] = useState({});
    const [showNotification, setShowNotification] = useState(false);

    const handleDelete = (email, name) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this API key? This action cannot be undone.");
    
        if (isConfirmed) {
            setLoading(true);
            deleteKey(email, name)
                .then(response => {
                    log(response); // handle the response here
                    // Add logic to update the UI or state after successful deletion
                })
                .catch(error => {
                    console.error("Error in deleting the key:", error);
                    alert("Failed to delete the API Key");
                })
                .finally(() => {
                    setLoading(false);
                    fetchApiKeys(setApiKeys, userEmail);
                });
        }
    };
  
    const updateModalPosition = useCallback(() => {
      const windowWidth = window.innerWidth;
      const marginLeft = sidebarVisible ? 90 : 0;
      const roomToWorkWith = sidebarVisible ? windowWidth - 180 : windowWidth
      const maxWidth = sidebarVisible ? roomToWorkWith * 0.9: roomToWorkWith * 0.9;
      
      setModalStyle({
        marginLeft: `${marginLeft}px`, // Use the calculated margin left
        maxWidth: `${maxWidth}px`, // Use the calculated max width
      });
    }, [sidebarVisible]);
  
    useEffect(() => {
      // Update the modal position initially and whenever the window is resized
      window.addEventListener('resize', updateModalPosition);
      updateModalPosition(); // Initial call to set position
  
      // Cleanup the event listener when the component unmounts
      return () => window.removeEventListener('resize', updateModalPosition);
    }, [updateModalPosition]);    


    useEffect(() => {
        setLoading(true)
        fetchApiKeys( setApiKeys, userEmail );
        setLoading(false)
    }, []);

    const generateNewApiKey = async () => {
        setLoading(true)
        newAPI(userEmail, pass)
            .then(response => {
                setNewApiKey(response.key);
            })
            .catch(error => {
                console.error("Error generating new key:", error);
            })
            .finally(() => {
                setLoading(false);
                setShowModal(true);
            });

    };

    const closeModal = () => {
        setShowModal(false);
        setNewApiKey('');
        fetchApiKeys( setApiKeys, userEmail );
    };

    const handleCopy = async () => {
      await navigator.clipboard.writeText(newApiKey);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 2000);
    };

    const sortedApiKeys = apiKeys.sort((a, b) => a.created - b.created);
    
    return (
        <div style={styles.wrapper}>
            <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
                <StyledTableContainer>
                    <h1 style={{marginTop: "20px"}}>Your API Keys</h1>
                    <button style={{marginTop: "30px"}} className='button' onClick={generateNewApiKey}>Generate New API Key</button>
                    <StyledTable isDarkMode={isDarkMode}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>API</th>
                                <th>Created</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedApiKeys.map(key => (
                                <tr key={key.id}>
                                    <td>{key.name}</td>
                                    <td>{"*".repeat(20) + key.api.slice(-6)}</td>
                                    <td>{formatTimestamp(key.created)}</td>
                                    <td>
                                        <span onClick={() => handleDelete(userEmail, key.name)} style={{ cursor: 'pointer', color: 'red' }}>
                                            🗑️
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <div className="loading-spinner" style={{ display: loading ? 'block' : 'none' }}>
                            <svg viewBox="0 0 50 50">
                                <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                            </svg>
                        </div>
                    </StyledTable>

                    {showModal && (
                        <Modal>
                            <ModalContent style={{...styles.body,  ...modalStyle }}>
                            <CloseButton onClick={closeModal}>&times;</CloseButton>
                                <h2>New API Key</h2>
                                <a>This is the only time you will ever see this key. Keep it private and secure.</a>
                                    <ModalFlexContainer>
                                        <FullWidthInput 
                                        className={isDarkMode ? 'sleek-input-api-dark' : 'sleek-input-api-light'} 
                                        type="text" 
                                        value={newApiKey} 
                                        readOnly 
                                        />
                                        <NotificationPopup className={showNotification ? 'show' : ''}>
                                          <Check size={16} />
                                          Copied to clipboard
                                        </NotificationPopup>
                                        <button className='button' onClick={handleCopy}>
                                          Copy to Clipboard
                                        </button>
                                    </ModalFlexContainer>
                            </ModalContent>
                        </Modal>
                    )}
                </StyledTableContainer>
            </div>
        </div>
    );
};

export default APIKeysPage;