import encrypt from "./encrypt";
import { log } from "../utils/logger";

const BASE_URL = 'https://register.vectorvault.io';

export async function storeAPI(email, brand, key) {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('brand', brand);
    formData.append('key', encrypt(key));

    try {
        const response = await fetch(`${BASE_URL}/store_api`, {
            method: 'POST',
            headers: {
                'Authorization': 'unexpected_authorization_code',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        });

        
        const data = await response.json();
        log('Success:', data);
        return data; // Returning the response data
    } catch (error) {
        console.error('Error:', error);
        throw error; // Throwing the error to be handled by the caller
    }
};


export async function checkAIKey(email, brand='openai') {
    const headers = {
      'Authorization': 'unexpected_authorization_code',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const data = new URLSearchParams({
        email: email,
        brand: brand
    });
    
    try {
        const response = await fetch(`${BASE_URL}/check_api`, {
            method: 'POST',
            headers: headers,
            body: data
        });
        
    if (response.status === 200) {
      const res = await response.json();
      return res.message;
    } else {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      return { error: 'Request failed with status code: ' + response.status + ', Message: ' + errorText };
    }
  } catch (error) {
    console.error('Request error:', error);
    return { error: error.message };
  }
};


export async function thirdPartyKeyDelete(email, brand) {
    const headers = {
      'Authorization': 'unexpected_authorization_code',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const data = new URLSearchParams({
        email: email,
        brand: brand
    });
    
    try {
        const response = await fetch(`${BASE_URL}/delete_third_party_api`, {
            method: 'POST',
            headers: headers,
            body: data
        });
        
    if (response.status === 200) {
        log(await response.json())
      return ;
    } else {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      return { error: 'Request failed with status code: ' + response.status + ', Message: ' + errorText };
    }
  } catch (error) {
    console.error('Request error:', error);
    return { error: error.message };
  }
};
