import encrypt from "./encrypt";

export const changePassword = async (email, pass, newPass) => {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('password', encrypt(pass));
    formData.append('new_password', encrypt(newPass));

    const headers = {
        'Authorization': 'expected_authorization_code', 
    };

    try {
        const response = await fetch('https://register.vectorvault.io/new_password', {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        console.error("Error occurred during API call:", error);
        return { error: error.message };
    }
};
