// ChatDashboard.js
import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import './Dashboard.css';
import '../App.css';
import { log } from "../utils/logger.js";
import ChatContainer from './ChatContainer.js'
import JSZip from 'jszip';
import { setCookie, getCookie, removeCookie} from '../cookieUtils.js';
import { debounce } from 'lodash';
import AddToVaultSection from "../components/AddToVault.js";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { AuthContext }  from '../App.js'
import { getFlowNames } from "../api/fetchFlows.js";

// Set the worker source to the locally hosted `pdf.worker.mjs`
pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";


function Dashboard({ Ampersandposium, setAmpersandposium, userEmail, styles, isDarkMode, wait, isMobile, displayValue, setDisplayValue }) {
  const { vectorVault } = useContext(AuthContext);
  const ChatContainerRef = useRef(null); // create a reference using the useRef hook
  const [notificationMessage, setNotificationMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customDataVisible, setCustomDataVisible] = useState(false);
  const [customData, setCustomData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(true);
  const [inputCode, setInputCode] = useState('');
  const [showVault, setShowVault] = useState(true);
  const [showPersonality, setShowPersonality] = useState(true);
  const [display, setDisplay] = useState(true);
  const [database, setDatabase] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const [personality, setPersonality] = useState(`Say everything like Snoop Dogg`);
  const [vaults, setVaults] = useState(() => {
    const storedVaults = getCookie('vaults');
    try {
      return storedVaults ? JSON.parse(storedVaults) : ['-'];
    } catch (e) {
      console.error("Error parsing vaults from cookie:", e);
      return ['-'];
    }
  });
  const [newVaultName, setNewVaultName] = useState(''); // useState for new vault name
  const [showCreateNewInput, setShowCreateNewInput] = useState(false);
  const newVaultInputRef = useRef(null);

  useEffect(() => {
    if (Ampersandposium?.startsWith('Flow: ')) {
      setShowPersonality(false);
      setShowVault(false);
    } else {
      setShowPersonality(true);
      setShowVault(true);
    }
  }, [Ampersandposium]); // Only run when Ampersandposium changes

  useEffect(() => {
    if (showCreateNewInput && newVaultInputRef.current) {
      newVaultInputRef.current.focus();
    }
  }, [showCreateNewInput]);


  // for calculating how many lines to show on the personality section
  const calculateRows = (text) => {
    if (!text) return 2;
    const charsPerLine = isMobile ? 50 : 100; // Fewer chars per line on mobile
    const lines = text.split('\n');
    
    return lines.reduce((total, line) => {
      return total + Math.ceil(line.length / charsPerLine);
    }, 0);
  };


  
  // Modified fetchVaults to avoid recursive updates
  const fetchVaults = useCallback(async () => {
    try {
      // Assuming vectorVault.getVaults() is the API call to fetch vaults
      log('getting vaults...');
      const fetchedVaults = await vectorVault.getVaults('');
      const flowNames = await getFlowNames(userEmail);
      const combinedVaults = [...fetchedVaults, ...flowNames, 'Create New'];
      setVaults(combinedVaults);
      setCookie('vaults', JSON.stringify(combinedVaults))
      log('vaults:', combinedVaults);
    } catch (error) {
      console.error('Error fetching vaults:', error);
      // Set default vaults if fetch fails
      const defaultVaults = ['-', 'Create New'];
      setVaults(defaultVaults);
      setCookie("vaults", JSON.stringify(defaultVaults));
    }
  }, [vectorVault]);

  useEffect(() => {
    if (!wait && userEmail) {
      fetchVaults();
    }
  }, [wait, userEmail, fetchVaults, vectorVault]);

  // Handler for selecting a vault
  const handleVaultChange = (e) => {
    const value = e.target.value;
    if (value === 'Create New') {
      setShowCreateNewInput(true);
      setNewVaultName('');
    } else {
      setShowCreateNewInput(false);
      updateAmpersandposium(value); 
    }
  };

  // Handler for creating a new vault
  const handleCreateNewVault = () => {
    if (newVaultName) {
      const updatedVaults = vaults.includes('Create New') 
        ? [...vaults.slice(0, -1), newVaultName, 'Create New']
        : [...vaults, newVaultName, 'Create New'];
      setVaults(updatedVaults);
      updateAmpersandposium(newVaultName);
      setCookie("vaults", JSON.stringify(updatedVaults));
      setShowCreateNewInput(false)
    } else {
      alert("Please enter a name for the new vault.");
    }
  };
  
  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (reloadData && !wait && Ampersandposium) {
      updateAmpersandposium(Ampersandposium);
    }
  }, [reloadData, wait, Ampersandposium]);

  useEffect(() => {
    if (!wait && Ampersandposium) {
      fetchPersonality()
    }
  }, [reloadData, wait, Ampersandposium]);

  // Updated debounced updateAmpersandposium
  const updateAmpersandposium = useCallback(
    debounce((value) => {
      setAmpersandposium(value);
      setCookie("Ampersandposium", value);
      setReloadData(prev => !prev);
    }, 300),
    []
  );
  
  const Notification = ({ message }) => {
    // Effect to automatically hide the notification after 5 seconds
    useEffect(() => {
      let timeoutId;
      if ( notificationMessage ) {
        // Set a timer to hide the notification
        timeoutId = setTimeout(() => {
          setNotificationMessage('')
        }, 5000);
      }
      
      // Clean up the timer
      return () => clearTimeout(timeoutId);
    }, [notificationMessage]);

    // Don't render the notification if it's not supposed to be shown
    if (!notificationMessage) {
        return null;
    }

    // Render the notification
    return (
      <div style={styles.notificationStyle}>
        {message}
      </div>
    );
  };


  async function handleFileUpload(event) {
    const file = event.target.files[0];
    log('file type:', file.type);
    setIsLoading(true);

    const reader = new FileReader();

    reader.onload = async function(event) {
        let fileContent;

        switch(file.type) {
            case 'text/plain': // .txt
            case 'text/csv':   // .csv
            case 'text/markdown': // .md
                fileContent = event.target.result;
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
                fileContent = await extractTextFromDocx(event.target.result);
                break;
            case 'application/pdf': // PDF case
                fileContent = await extractTextFromPDF(file);
                break;
            default:
                setIsLoading(false);
                alert("Unsupported file type.");
                return;
        }

          await vectorVault.addCloud({ text: fileContent, name: file.name })
            .then(response => response.json())
            .then(data => {
                log(data);
            })
            .catch(error => {
                console.error("Error during fetch:", error);
            })
            .finally(() => {
                event.target.value = null;
                setIsLoading(false); // Hide spinner
                setReloadData(prevState => !prevState); // Reload data
                if (file.type.startsWith('image/')) {
                    alert("Text successfully extracted.");
                } else {
                    alert("File uploaded successfully.");
                }
            });
    };

    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'text/markdown') {
        reader.readAsText(file);
    } else if (file.type === 'application/pdf' || 
               file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               file.type.startsWith('image/')) {  // For all image types, including heic, jpeg, png
        reader.readAsArrayBuffer(file);
    } else {
        setIsLoading(false);
        alert("Unsupported file type.");
    }
  }

  // Helper function to extract text from PDF
  async function extractTextFromPDF(file) {
    const arrayBuffer = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);  // Ensure file is read as ArrayBuffer
    });

    const pdfDoc = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let extractedText = '';

    for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        extractedText += pageText + '\n';
    }

    return extractedText;
  }

  async function extractTextFromDocx(arrayBuffer) {
      const zip = new JSZip();
      const content = await zip.loadAsync(arrayBuffer);
      const docXML = await content.files["word/document.xml"].async("text");
      const parser = new DOMParser();
      const docNode = parser.parseFromString(docXML, "application/xml");

      const nsResolver = (prefix) => {
          return {
              'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
          }[prefix] || null;
      };

      const textNodeResults = docNode.evaluate('//w:t', docNode, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      let textContentArray = [];
      for (let i = 0; i < textNodeResults.snapshotLength; i++) {
          textContentArray.push(textNodeResults.snapshotItem(i).textContent);
      }
      
      return textContentArray.join(" ");
  }
  
  async function handleCustomDataSave(customDataContent) {
      setIsLoading(true); 
      await vectorVault.addCloud({ vault: Ampersandposium, text : customDataContent })
      .catch(error => {
          console.error("There was an error adding your data to the cloud:", error);
      })
      .finally(() => {
          setIsLoading(false); // Hide spinner when the fetch is complete
          setCustomData('')
          setReloadData(prevState => !prevState); // Reload data
          alert("Data uploaded successfully.")
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCreateNewVault();
    }
  };

  async function handlePersonalitySave(personalityMessage) {
      setIsLoading(true); 
      setPersonality(personalityMessage)
      await vectorVault.savePersonalityMessage(Ampersandposium, personalityMessage)
      alert("Personality saved successfully.") 
      setIsLoading(false); 
  }

  const fetchPersonality = useCallback(async () => {
    try {
      const message = await vectorVault.fetchPersonalityMessage(Ampersandposium);
      setPersonality(message);
    } catch (error) {
      console.error("Error fetching personality message:", error);
    }
  }, [Ampersandposium]); // Dependencies
  
  useEffect(() => {
    if (!wait) {
      // Ensure that the fetch is only attempted when all required values are available
      if (userEmail && Ampersandposium) {
        fetchPersonality();
      }
    }
  }, [wait, Ampersandposium]);
  
  
  // Update handleSiteUpload to accept siteUrl as a parameter
  async function handleSiteUpload(siteUrl) {
    setIsLoading(true);
    await vectorVault.addSite({ vault: Ampersandposium, site: siteUrl, name: siteUrl })
      .then(response => response.json())
      .then(data => {
        log(data);
      })
      .catch(error => {
        console.error("There was an error with the fetch operation:", error);
      })
      .finally(() => {
        setIsLoading(false);
        setReloadData(prevState => !prevState);
        alert("Site uploaded successfully.");
      });
  }


  const handleDelete = () => {
    setShowModal(true); // Show the modal when the delete button is clicked
  }

  async function confirmDeletion() {
    if (inputCode === "DELETE") {
      setShowModal(false);
      setIsLoading(true); // Show spinner
      try {
        const response = await vectorVault.deleteVault(Ampersandposium);
        log('delete response', response);
        updateAmpersandposium(vaults[0])
        fetchVaults()
        alert("Database deleted successfully.");
      } catch (error) {
        console.error("There was an error with the fetch operation:", error);
        alert("Error deleting database.");
      } finally {
        setIsLoading(false); // Hide spinner
        setReloadData(prevState => !prevState); // Reload data
        fetchVaults();
        setInputCode(''); // Reset the input code
      }
    } else {
      alert('You must type "DELETE" to confirm you want to delete the entire database');
      setInputCode(''); // Reset the input for another try
    }
  }

  document.addEventListener("touchstart", function() {
    if (document.body.style.zoom !== "1") {
      document.body.style.zoom = 1;
    }
  });

  return (
    <div style={styles.wrapper}>
      <div className="App" style={{ ...styles.body, paddingTop: "50px" }}>
        {/* <h1 style={{ ...styles.h1, textAlign: "left", fontSize: isMobile ? "1.5rem" : "2.9rem", display: "flex", alignItems: "left", marginTop: isMobile ? "50px" : "45px"}}>
          {Ampersandposium}
        </h1> */}
        <div style={{ marginTop: "20px" }}>
          {display && (
            <>
              {!showDelete && <div style={{ marginTop: "-40px" }}></div>}
              {showDelete && (
                <>
                  <div style={{ marginTop: "-10px" }}></div>
                  {database && (
                    <>
                      <div style={{ marginTop: '15px' }}></div>
                    <h3 style={{marginBottom: "20px"}}>Vault: {Ampersandposium}</h3>
                      {showCreateNewInput ? (
                        <div>
                          <input
                            ref={newVaultInputRef}
                            type="text"
                            className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                            value={newVaultName}
                            onChange={(e) => setNewVaultName(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Enter new vault name"
                          />
                          <button style={{ ...styles.btn }} onClick={handleCreateNewVault}>Create</button>
                        </div>
                      ) : (
                        <>
                          <select
                            className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                            value={Ampersandposium}
                            onChange={handleVaultChange}
                          >
                            {Array.isArray(vaults) && vaults.map(vault => (
                              <option key={vault} value={vault}>{vault}</option>
                            ))}
                          </select>

                        {showVault && <button style={{ ...styles.btn, marginLeft: "-5px" }} onClick={() => handleDelete()}>Delete</button> }
                        </>
                      )}

                    {showVault &&
                      <>
                        <div style={{ marginTop: "20px" }}>
                          <AddToVaultSection 
                            isDarkMode={isDarkMode}
                            handleFileUpload={handleFileUpload}
                            handleSiteUpload={handleSiteUpload}
                            handleCustomDataSave={handleCustomDataSave}
                            isMobile={isMobile}
                            styles={styles}
                          />
                        </div>
                      </>
                    }
                    </>
                  )}
                  <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                    <svg viewBox="0 0 50 50">
                      <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                    </svg>
                  </div>
                  <div>
                  {showModal && (
                    <div className={isDarkMode ? "modall modall-dark" : "modall"}>
                      <div className={isDarkMode ? "modal-content modal-content-dark" : "modal-content"}>
                        <div className="modal-header">
                          <h3>Delete Vault</h3>
                          <button className={isDarkMode ? 'btn-dark' : 'btn-light'} onClick={() => setShowModal(false)}>Close</button>
                        </div>
                        <div className="modal-body">
                          <p>Type "DELETE" below to confirm you want to permanently delete the entire database <b>{Ampersandposium}</b>:</p>
                          <input
                          className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                            type="text"
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                          />
                        <button onClick={confirmDeletion} style={{marginLeft: '5px'}}>Confirm</button>
                        </div>
                      </div>
                    </div>
                  )}
                    {customDataVisible && (
                      <>
                        <textarea
                          placeholder={`Type or paste something you want to add to the database...`}
                          id="chat-message"
                          rows={isMobile ? 15 : 10}
                          style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px" }}
                          value={customData}
                          onChange={(e) => setCustomData(e.target.value)}
                        />
                        <button style={{ ...styles.button, marginTop: "0px", marginLeft: "0px" }} onClick={() => handleCustomDataSave(customData)}>Save to database</button>
                        <br />
                      </>
                    )}
                    <div style={{marginBottom: "-10px"}} />
                    {showPersonality &&
                    <>
                      <button className={isDarkMode ? 'btn-dark' : 'btn-light'} style={{ marginTop: "30px", marginLeft: "0px" }} onClick={() => setIsVisible(!isVisible)}>
                        {isVisible ? 'Hide' : 'Edit'} Personality
                      </button>
                      {isVisible && (
                        <>
                          <textarea
                            placeholder={personality}
                            id="chat-message"
                            rows={calculateRows(personality)}
                            style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px" }}
                            value={personality}
                            onChange={(e) => setPersonality(e.target.value)}
                          />
                          <button style={{ ...styles.button, marginTop: "0px", marginLeft: "1px" }} onClick={() => handlePersonalitySave(personality)}>Save Personality</button>
                        </>
                      )}
                    </>
                    }
                  </div>
                  <br />
                </>
              )}
            </>
          )}
        </div>
        {notificationMessage && (
          <div style={{ marginTop: "10px" }}>
            <Notification
              message={notificationMessage}
            />
          </div>
        )}
        <ChatContainer
          isDarkMode={isDarkMode}
          ref={ChatContainerRef}
          isMobile={isMobile}
          windowWidth={windowWidth}
          Ampersandposium={Ampersandposium}
          display={display}
          reloadData={reloadData}
          showDelete={showDelete}
          userEmail={userEmail}
          wait={wait}
          styles={styles}
          setNotificationMessage={setNotificationMessage}
          displayValue={displayValue}
          setDisplayValue={setDisplayValue}
        />
      </div>
    </div>
  );
}

export default Dashboard;



